import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './components/modals';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface Order {
  id: number;
  status: string;
  currency: string;
  version: string;
  prices_include_tax: boolean;
  date_created: string | null;
  date_modified: string | null;
  discount_total: number;
  discount_tax: number;
  shipping_total: number;
  shipping_tax: number;
  cart_tax: number;
  total: number;
  total_tax: number;
  customer_id: number;
  order_key: string;
  billing_first_name: string;
  billing_last_name: string;
  billing_company: string | null;
  billing_address_1: string;
  billing_address_2: string | null;
  billing_city: string;
  billing_state: string;
  billing_postcode: string | null;
  billing_country: string;
  billing_email: string;
  billing_phone: string;
  created_at: string | null;
  updated_at: string;
}

const OrdersTable: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://backend.stickerseller.cl/api/orders');
        if (response.ok) {
          const data = await response.json();
          setOrders(data);
        } else {
          console.error('Failed to fetch orders:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };
    fetchOrders();
  }, []);

  const filteredOrders = orders.filter(order => order.status.toLowerCase().includes(filter.toLowerCase()));

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const createWorkOrder = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://backend.stickerseller.cl/api/populate_extracted', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ order_ids: selectedOrders }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        const workOrderId = data.work_order_id;
        closeModal();
        console.log(workOrderId)
        navigate(`/work_order/${workOrderId}`);
      } else {
        console.error('Failed to create work order:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating work order:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToDetail = (orderId: number) => {
    navigate(`/order/${orderId}`);
  };

  const handleRowClick = (orderId: number) => {
    navigate(`/order/${orderId}`);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, orderId: number) => {
    event.stopPropagation(); // Stop the event from bubbling up to the row's onClick event
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedOrders([...selectedOrders, orderId]);
    } else {
      setSelectedOrders(selectedOrders.filter(id => id !== orderId));
    }
  };

  return (
    <div className="overflow-x-auto">
      <div className="p-5">
        <label htmlFor="statusFilter" className="block text-sm font-medium text-gray-700 mb-2">Filtrar por estado:</label>
        <input
          id="statusFilter"
          type="text"
          className="form-input mt-1 block w-full rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring"
          placeholder="Ej: Processing"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200 text-left">
            <th className="py-2 px-4">ID</th>
            <th className="py-2 px-4">Nombre</th>
            <th className="py-2 px-4">Apellido</th>
            <th className="py-2 px-4">Email</th>
            <th className="py-2 px-4">Total</th>
            <th className="py-2 px-4">Estado</th>
            <th className="py-2 px-4">Detalle</th>
            <th className="py-2 px-4">Seleccionar</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.length > 0 ? filteredOrders.map(order => (
            <tr key={order.id} className="border-t border-gray-200 cursor-pointer" >
              <td className="py-2 px-4">{order.id}</td>
              <td className="py-2 px-4">{order.billing_first_name}</td>
              <td className="py-2 px-4">{order.billing_last_name}</td>
              <td className="py-2 px-4">{order.billing_email}</td>
              <td className="py-2 px-4">{order.total}</td>
              <td className="py-2 px-4">{order.status}</td>
              <td className="py-2 px-4">
                <button onClick={() => handleNavigateToDetail(order.id)} className="text-green-500">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              </td>
              <td className="py-2 px-4">
                <input
                  type="checkbox"
                  checked={selectedOrders.includes(order.id)}
                  onChange={(e) => handleCheckboxChange(e, order.id)}
                  disabled={order.status === 'Processing'}
                />
              </td>
            </tr>
          )) : (
            <tr>
              <td colSpan={7} className="py-2 px-4 text-center">No orders found.</td>
            </tr>
          )}
        </tbody>
      </table>
      {selectedOrders.length > 0 && (
        <button className="mt-4 px-4 py-2 bg-blue-600 text-white rounded" onClick={openModal}>Crear OT</button>
      )}
      <Modal isOpen={isModalOpen} onClose={closeModal} onConfirm={createWorkOrder} title="Crear Orden de Trabajo">
        {loading ? <div className="flex justify-center"><div className="loader"></div></div> : <p>Estás a punto de crear una orden de trabajo para las órdenes seleccionadas. ¿Deseas continuar?</p>}
      </Modal>
    </div>
  );
};

export default OrdersTable;