import React from 'react';
// import { Shop } from '../../../types/types';

export interface Shop {
  id: number;
  name: string;
  description: string;
  location: string;
}

interface ShopCardProps {
  shop: Shop;
}

const ShopCard: React.FC<ShopCardProps> = ({ shop }) => {
  return (
    <div className="bg-white shadow-md rounded px-4 py-2 mb-4">
      <h2 className="text-lg font-bold">{shop.name}</h2>
      <p>{shop.description}</p>
      <p className="text-sm">{shop.location}</p>
    </div>
  );
};

export default ShopCard;

