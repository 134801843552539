import React from 'react';

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  imageUrl: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-75 z-40" onClick={onClose}></div>
      <div className="z-50 relative max-w-4xl max-h-[90%] mx-auto my-auto flex justify-center items-center">
        <div className="relative">
          <img src={imageUrl} alt="Extracted Item" className="max-w-full max-h-screen object-contain rounded-md" />
          <button
            className="absolute top-0 right-0 mt-2 mr-2 text-white hover:text-gray-300"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
