// components/WorkOrders/WorkOrders.tsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

interface WorkOrder {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
}

const WorkOrders: React.FC = () => {
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchWorkOrders = async () => {
      try {
        const response = await fetch('https://backend.stickerseller.cl/api/work_orders');
        if (response.ok) {
          const data = await response.json();
          setWorkOrders(data);
        } else {
          setError('Failed to fetch work orders.');
        }
      } catch (error) {
        setError('Error fetching work orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchWorkOrders();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Listado de Órdenes de Trabajo</h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200 text-left">
            <th className="py-2 px-4">ID</th>
            <th className="py-2 px-4">Estado</th>
            <th className="py-2 px-4">Fecha de Creación</th>
            <th className="py-2 px-4">Última Actualización</th>
            <th className="py-2 px-4">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {workOrders.map((workOrder) => (
            <tr key={workOrder.id} className="border-t border-gray-200">
              <td className="py-2 px-4">{workOrder.id}</td>
              <td className="py-2 px-4">{workOrder.status}</td>
              <td className="py-2 px-4">{workOrder.created_at}</td>
              <td className="py-2 px-4">{workOrder.updated_at}</td>
              <td className="py-2 px-4">
                <Link
                  to={`/work_order/${workOrder.id}`}
                  className="text-blue-500 hover:underline"
                >
                  Ver Detalles
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WorkOrders;
