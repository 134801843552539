import React, { useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void; // Nueva prop para manejar la confirmación
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50 z-40" onClick={onClose}></div>
      <div className="bg-white rounded-lg overflow-hidden shadow-lg max-w-lg w-full z-50 relative">
        <div className="p-4 border-b">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button
            className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="p-4">
          {children}
        </div>
        <div className="flex justify-end p-4 border-t">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            onClick={onConfirm} // Llamar a onConfirm cuando se hace clic en "Crear OT"
          >
            Crear OT
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
