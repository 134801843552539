// App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './modules/Home/Home';
import OrdersTable from './modules/Orders/Orders';
import WorkOrderDetail from './modules/Orders/components/WorkOrderDetail';
import LoginForm from './modules/Auth/components/LoginForm';
import SignupForm from './modules/Auth/components/SignupForm';
import ChangePasswordForm from './modules/Auth/components/ChangePasswordForm';
import WorkOrders from './modules/Orders/components/workOrders';
import OrderDetail from './modules/Orders/components/orderDetail';
import Shops from './modules/Shops/Shops';
const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

const AppContent: React.FC = () => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('guest');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('userRole');
    setIsAuthenticated(!!token);
    setUserRole(role || 'guest');
  }, [location]);

  return (
    <>
      {isAuthenticated && <Navbar />}
      <div className={`flex ${isAuthenticated ? '' : 'justify-center'}`}>
        {isAuthenticated && <Sidebar isAuthenticated={isAuthenticated} userRole={userRole} />}
        <div className={`flex flex-col flex-grow ${isAuthenticated ? 'ml-10' : ''}`}>
          <div className="p-4">
            <Routes>
              <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
              <Route path="/orders" element={isAuthenticated ? <OrdersTable /> : <Navigate to="/login" />} />
              <Route path="/order/:order_id" element={isAuthenticated ? <OrderDetail /> : <Navigate to="/login" />} />
              <Route path="/work_orders" element={isAuthenticated && userRole === 'admin' ? <WorkOrders /> : <Navigate to="/login" />} />
              <Route path="/work_order/:work_order_id" element={isAuthenticated && userRole === 'admin' ? <WorkOrderDetail /> : <Navigate to="/login" />} />
              <Route path="/login" element={!isAuthenticated ? <LoginForm /> : <Navigate to="/" />} />
              <Route path="/signup" element={!isAuthenticated ? <SignupForm /> : <Navigate to="/" />} />
              <Route path="/change-password" element={isAuthenticated ? <ChangePasswordForm /> : <Navigate to="/login" />} />

              <Route path="/shops" element={isAuthenticated && userRole === 'seller' ? <Shops /> : <Navigate to="/login" />
        } />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;